//
// map.js
// Theme module
//

// const maps = document.querySelectorAll('[data-map]');
// const accessToken = 'pk.eyJ1IjoiZ29vZHRoZW1lcyIsImEiOiJjanU5eHR4N2cybDU5NGVwOHZwNGprb3E0In0.msdw9q16dh8v4azJXUdiXg';

// maps.forEach((map) => {
//   const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

//   const defaultOptions = {
//     container: map,
//     style: 'mapbox://styles/mapbox/light-v9',
//     scrollZoom: false,
//     interactive: false,
//   };

//   const options = {
//     ...defaultOptions,
//     ...elementOptions,
//   };

//   // Get access token
//   mapboxgl.accessToken = accessToken;

//   // Init map
//   new mapboxgl.Map(options);
// });


import { Loader } from '@googlemaps/js-api-loader';


if (document.getElementById("map")) {

  const loader = new Loader({
    apiKey: "AIzaSyDwf4aFypk6wF8Jnrnh0zDB_O7vUYB3OfU",
    version: "weekly"
  });

  loader.load().then(async () => {
    const { Map } = await google.maps.importLibrary("maps");

    let map;

    const element = document.querySelector("#map");
    var latitude = Number(element.dataset.lat);
    var longitude = Number(element.dataset.lng);
    var color = element.dataset.color;
    var zoom = Number(element.dataset.zoom);

    console.log( latitude);

    map = new Map(document.getElementById("map"), {
      center: { lat: latitude, lng: longitude },
      zoom: zoom,
      mapId: 'f08bbcea37c3ee70',
      disableDefaultUI: true,
    });

    const office = { lat: latitude, lng: longitude };          

      const svgMarker = {
          path: "M4.89941 5.09863C1.95508 8.04297 0.300781 12.0361 0.300781 16.2002C0.300781 28.5 16 43.5 16 43.5C16 43.5 31.7002 28.5 31.7002 16.2002C31.7002 12.0361 30.0459 8.04297 27.1016 5.09863C24.1572 2.1543 20.1641 0.5 16.001 0.5C11.8369 0.5 7.84375 2.1543 4.89941 5.09863Z",
          fillColor: color,
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          anchor: new google.maps.Point(15, 40),
      };

    const marker = new google.maps.Marker({
      position: office,
      icon: svgMarker,
      map: map,
    });

  });

}