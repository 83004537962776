  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';

  function swipers() {
    // init Swiper:
    const swiper = new Swiper('.bloom-swiper-realisations', {
        //Optional parameters
        direction: 'horizontal',
        autoplay: false,
        breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 40
            }
        },
    
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    
        watchSlidesProgress: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    
        // And if we need scrollbar
        scrollbar: {
        el: '.swiper-scrollbar',
        },

        grabCursor: true,

    });

    const swiperLogos = new Swiper('.bloom-swiper-logos', {
      //Optional parameters
      direction: 'horizontal',
      loop: true,
      speed: 3000,
      allowTouchMove: false,
      slidesPerView: 2.5,
      spaceBetween: 20,
      autoplay: {
        delay: 0,
      },
      breakpoints: {
          320: {
            slidesPerView: 3.5,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 7,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 8,
            spaceBetween: 40
          },
          1400: {
            slidesPerView: 10,
            spaceBetween: 40
          },
          1600: {
            slidesPerView: 12,
            spaceBetween: 40
          }
      }
    });

    const swiperTemoignages = new Swiper('.bloom-swiper-temoignages', {
      //Optional parameters
      direction: 'horizontal',
      effect: 'fade',
      speed: 600,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },
      slidesPerView: 1,
  
      // If we need pagination
      pagination: {
          el: '.swiper-pagination',
          clickable: true,
      },
  
      watchSlidesProgress: true,
      // Navigation arrows
  
      // And if we need scrollbar
      scrollbar: {
      el: '.swiper-scrollbar',
      },

      grabCursor: true,

  });
      
}

swipers();

export { swipers };
