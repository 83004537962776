// 
// main.js
//


var body = document.body;

var navbar = document.getElementById("bloom-navbar");

function onscrollNavbar() {
  if (window.pageYOffset >= 100) {
      navbar.classList.add("on-scroll");
      body.classList.add("on-scroll");
  } else {
      navbar.classList.remove("on-scroll");
      body.classList.remove("on-scroll");
  }
}

window.onscroll = function() {
    onscrollNavbar();
};

window.onload  = function() {
    onscrollNavbar();
}

document.querySelectorAll('.bloom-service-preview-video').forEach(function(vid) {
    vid.onmouseover = function() {
    this.play();
    }
    vid.onmouseout = function() {
    this.pause(); // stop and show poster
    }
})

// var mapElement = document.getElementById("map");

//     if (mapElement) {
//       let map;

//       function initMap() {
          
//           const office = { lat: 48.36537863654492, lng: -4.549006168599715 };
  
//           const map = new google.maps.Map(document.getElementById("map"), {
//           center: { lat: 48.36537863654492, lng: -4.549006168599715 },
//           zoom: 11,
//           mapId: 'f08bbcea37c3ee70',
//           disableDefaultUI: true,
//           });
          
  
//           const svgMarker = {
//               path: "M4.89941 5.09863C1.95508 8.04297 0.300781 12.0361 0.300781 16.2002C0.300781 28.5 16 43.5 16 43.5C16 43.5 31.7002 28.5 31.7002 16.2002C31.7002 12.0361 30.0459 8.04297 27.1016 5.09863C24.1572 2.1543 20.1641 0.5 16.001 0.5C11.8369 0.5 7.84375 2.1543 4.89941 5.09863Z",
//               fillColor: "#FBBD02",
//               fillOpacity: 1,
//               strokeWeight: 0,
//               rotation: 0,
//               anchor: new google.maps.Point(15, 40),
//           };
  
//           const marker = new google.maps.Marker({
//           position: office,
//           icon: svgMarker,
//           map: map,
//           });
  
//       }
//     }    

